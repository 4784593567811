import React from 'react';
import Home from './pages/home';
import Privacy from './pages/privacy';

function App() {
  return (
    window.location.pathname === '/privacy' ? <Privacy /> : <Home />
  );
}

export default App;
