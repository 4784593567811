import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import privacy from './privacy.md';
import './style.css';

class Privacy extends Component {
	state = {
		content: ''
	}

  componentWillMount() {
    fetch(privacy)
      .then(res => res.text())
      .then(text => this.setState({ content: text }));
  }

 	render() {
 		return (
 			<div className="markdown-box">
 				<ReactMarkdown source={this.state.content} />
 			</div>
 		)
 	}
}

export default Privacy;