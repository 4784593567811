import React from 'react';
import mainpic from '../../../statics/image/main-mobile.png';

const MainPic = () => (
	<div className="main-pic-wrapper">
		<img 
			src={mainpic} 
			className="main-pic"
			alt=""
		/>
		<div className="main-content">
			<h3 className="main-title">
				Software Development Team
			</h3>
			<p className="main-desc">
				Share your challenge with our team and we’ll work with you to deliver a revolutionary digital product.
			</p>
			<a className="main-mail-to" href="mailto:bd@curve.im">
				<div className="main-button">Get In Touch</div>
			</a>
		</div>
	</div>
);

export default MainPic;