import React from 'react';
import logo from '../../../statics/image/logo.png';

const Header = () => {
	return (
		<div className="header-wrapper">
			<div className="header-logo-wrapper">
				<img 
					src={logo}  
					className="header-logo"
					alt=""
				/>
			</div>
			<ul className="header-nav">
				<li className="header-nav-item nav-active">
					<a 
						href="/"
						className="header-nav-link"
					>Home</a>
				</li>
{/*				<li className="header-nav-item">
					<a 
						className="header-nav-link"
					>About</a>
				</li>*/}
			</ul>
		</div>
	);
}

export default Header;