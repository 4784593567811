import React from 'react';
import wd from '../../../statics/image/service-wd.png';
import md from '../../../statics/image/service-md.png';
import pd from '../../../statics/image/service-pd.png';
import vm from '../../../statics/image/service-vm.png';

const services = [
	{
		type: 'Web development',
		url: wd
	},{
		type: 'Mobile development',
		url: md
	},{
		type: 'Product design',
		url: pd
	},{
		type: 'Wechat Miniprogram',
		url: vm
	}
]

const ServiceItem = (props) => {
	const { url, type } = props;

	return (
		<div className="service-item">
			<img 
				src={url} 
				className="service-item-img"
				alt=""
			/>
			<div className="service-item-type">
				{type}
			</div>
		</div>
	);
}

const Service = () => (
	<div className="service-wrapper">
		<p className="service-info">What can we do for you?</p>
		<div className="service-list">
			{
				services.map(item => <ServiceItem key={item.type} url={item.url} type={item.type}/>)
			}
		</div>
	</div> 
);

export default Service;