import React from 'react';
import './style.css';
import Header from './components/Header';
import MainPic from './components/MainPic';
import Service from './components/Service';
import Products from './components/Products';
import Footer from './components/Footer';

const Home = (props) => {
	return (
		<div className="index-wrapper">
			<Header />
			<MainPic />
			<Service />
			<Products />
			<Footer />
		</div>
	)
}

export default Home;