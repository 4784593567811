import React from 'react';
import renaissanceLogo from '../../../statics/image/logo-renaissance.png';
import renaissance from '../../../statics/image/renaissance.png';
import sicanLogo from '../../../statics/image/logo-sican.png';
import sican from '../../../statics/image/sican.png';
import kindLogo from '../../../statics/image/logo-kind.png';
import kind from '../../../statics/image/kind.png';

const ProductList = [
	{
		logo: renaissanceLogo,
		name: 'Renaissance',
		desc: 'Listen to Earn',
		intro: 'Renaissence enables a direct connection between the music you listened and the artists behind it',
		tag: ['#Blockchain', '#Music'],
		pic: renaissance,
		mode: 'rightSide',
		backclr: 'rgb(250, 250, 251)'
	},
	{
		logo: kindLogo,
		name: 'Kind',
		desc: 'Discover Kindred Spirits',
		intro: '',
		tag: ['#Social', '#Chat', '#Communication'],
		pic: kind,
		mode: 'leftSide',
		backclr: 'rgb(255, 255, 255)'
	},
	{
		logo: sicanLogo,
		name: 'S!can',
		desc: 'Scan to pay quickly',
		intro: '',
		tag: ['#Payment Merchant', '#Data Analytics', '#Data Visualization'],
		pic: sican,
		mode: 'rightSide',
		backclr: 'rgb(250, 250, 251)'
	}
]


const ProductItem = (props) => {
	const { logo, name, desc, intro, tag, pic, mode, backclr } = props;

	return (
		<div 
			className="product-box" 
			style={{backgroundColor: backclr}}
		>
			<div className="product-item">
				<div className={"product-info " + mode}>
					<img
						className="product-logo" 
						src={logo}
						alt=""
					/>
					<div className="product-name">{name}</div>
					<div className="product-desc">{desc}</div>
					<div className="product-intro">{intro}</div>
					{
						tag.map(item => 
							<div className="product-tags" key={item}>
								<span className="product-tag">{item}</span>
							</div>
						)
					}
				</div>
				<div className="product-pic-wrapper">
					<img src={pic} className="product-pic" alt="" />
				</div>
			</div>
		</div>
	)
}

const Products = () => (
	ProductList.map(item => (
		<ProductItem {...item} key={item.name} />
	))
)

export default Products;